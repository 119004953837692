import Partners from "../Components/Partners/Partners";
import Services from "../Components/Services/Services";
export const Partner = () => {
  document.body.scrollTop = 0;
  return (
    <div>
      <Partners />
      <Services />
    </div>
  );
};
